import React from 'react';
import strings from "../config/strings";
import Image from "../components/Image"
import Utils from "../utils/utils"
import {SECONDARY_COLOR} from "../config/styles";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';



const COLORS = ['#ffff00', '#FF6565', '#FFB526', '#A6EF79', '#6AEDFF', '#2BA5F7', '#888', '#000'];

const OPTIONS_HEIGHT = 150

class DrawingOptions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {color: COLORS[0], transparent:true, selectArea: true, brushSize: 5, expanded: true};
  }
  renderColorItem(item, index) {
    return (
      <TouchableOpacity
        onPress={() => {

          this.props.setColor(item)
          this.setState({color: item, isErasing: false})}

        }
        style={{
          width: 20,
          height: 20,
          margin: 4,
          borderRadius: 10,
          backgroundColor: item,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: item == this.state.color ? '#fff' : 'transparent',
          borderWidth: 2,
          //opacity: 0.5
        }}
      />
    );
  }

  renderColors() {
    return (
      <FlatList
        data={COLORS}
        renderItem={(item) => this.renderColorItem(item.item, item.index)}
        keyExtractor={(item) => item.item}
        contentContainerStyle={{flexGrow: 0}}
        style={{flexGrow: 0}}
        numColumns={COLORS.length}
      />
    );
  }


  renderCollapsed() {
    return (
      <TouchableOpacity
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: 40,
          height: 40,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#2ba5f7',
          borderRadius: 20,
          margin: 20,
          zIndex: 200
        }}
        onPress={() => {
          this.setState({expanded: true})
        }
        }>
        <Image
          urlOnServer={'/imgs/draw_circle_on.png'}
          style={{width: 25, height: 25}}
        />
      </TouchableOpacity>
    );
  }

  renderOneTool(name, image, active, onPress, color = "#F3C639") {
    var unactiveColor = "#444"
    return (
      <TouchableOpacity
      style={{
        width: name.length > 10 ? 90 : 75,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal:2,
        marginTop: 8,
        //marginLeft:-8,

      }}
      onPress={onPress}>
        <View style={{
          backgroundColor: active ?'rgba(0,0,0,0.2)' :  'rgba(255,255,255,0.5)',
          width: 32,
          height: 32,
          borderRadius: 20,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image
        urlOnServer={image}
        style={{width: 14, height: 14, tintColor: active ? color : unactiveColor}}
      />
        </View>

      <Text style={[styles.title,{
        marginTop: 6,
      }]}>{Utils.capitalizeFirstLetter(name)}</Text>
    </TouchableOpacity>
    )
  }

  render() {

    if (!this.state.expanded)
      return this.renderCollapsed()
    return (

      <View style={{
            zIndex: 200,
            paddingHorizontal: 20,
            paddingVertical: 8,
            width:'100%',
            backgroundColor: "rgba(0,0,0,0.5)",
            borderBottomRightRadius: 20,
          }}>
        <View
          style={{
            flexDirection:'row',
            alignItems:'center',

          }}>
          <Text style={[styles.title, {minWidth: 50}]}>{strings.size}:</Text>


          <input
           style={{width: 80, height: 20, margin: 4}}
           type="range"
           min="1"
           max="40"
           value={this.state.brushSize}
           onChange={(e) => {
            this.setState({brushSize: e.target.value})
            this.props.setBrushSize(e.target.value)}}
        />
          <View
            style={{
              flexDirection: 'row',

              alignItems:'center',
              paddingVertical:4
            }}>
            <Text style={[styles.title, {minWidth: 50}]}>
              {strings.color}:
            </Text>
            {this.renderColors()}

            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom:16
            }}>
            {
              this.renderOneTool(
                strings.transparent,
                '../imgs/draw-marker.png',
                (!this.state.isErasing && this.state.transparent),
                () => {
                  this.setState({ isErasing: false, transparent: true })
                  this.props.setTransparent(true)
                  this.props.setErasing(false)
                }
                )
            }

            {
              this.renderOneTool(
                strings.opaque,
                '../imgs/draw-pen.png',
                (!this.state.isErasing && !this.state.transparent),
                () => {
                  this.setState({ isErasing: false, transparent: false })
                  this.props.setTransparent(false)
                  this.props.setErasing(false)
                }
                )
            }
            {
              this.renderOneTool(
                strings.eraser,
                '../imgs/draw-eraser.png',
                this.state.isErasing,
                () =>  { this.setState({ isErasing: true })
                this.props.setErasing(true)
                })
                
            }



              <View style={{flex:1}}/>
              <TouchableOpacity
                style={{

                  height: 40,
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
                onPress={() => {

                  this.setState({expanded: false})
                }
                }>
                <Image
                  urlOnServer={'/imgs/expand2.png'}
                  style={{width: 20, height: 20, tintColor: "#fff"}}
                />

              </TouchableOpacity>




          </View>


        </View>


      )
  }


}
const styles = StyleSheet.create({
title: {
  color: "#fff",
  letterSpacing: 1,
  fontSize: 12,
}
});
export default DrawingOptions;
