import React, { useState } from 'react';
import logo from './logo.svg';
import { HEADER_HEIGHT } from './config/Constants'
import './App.css';
import Profile from './containers/Profile';
import Login from './containers/Login';


import Tools from './containers/Tools';
import ComputeExample from './containers/ComputeExample';
import ComputeWool from './containers/ComputeWool';
import ConvertUnits from './containers/ConvertUnits';
import ConvertSizes from './containers/ConvertSizes';
import DistribStitches from './containers/DistribStitches';
import RcPatterns from './containers/RcPatterns';
import Forum from './containers/Forum';
import Chat from './containers/Chat';



import UploadLocalFile from './containers/UploadLocalFile';
import TestResult from './containers/TestResult';
import NewProject from './containers/NewProject'
import ViewProject from './containers/ViewProject'
import EditCounterList from './containers/EditCounterList'
import EditComment from './containers/EditComment'
import RavelryAuth from './containers/RavelryAuth'
import Glossary from './containers/Glossary'
import PatternList from './containers/generator/PatternList'
import InitPattern from './containers/generator/InitPattern'
import EditPattern from './containers/generator/EditPattern'
import CreateSection from './containers/generator/CreateSection'
import CreateImageSection from './containers/generator/CreateImageSection'
import { SchemaGeneratorWithRouter } from './containers/generator/SchemaGenerator'
import { ChartListWithRouter } from './containers/generator/ChartList'
import { EditChartSymbolWithRouter } from './containers/generator/EditChartSymbol'
import { ShowChartPreviewWithRouter } from './containers/generator/ShowChartPreview'

//import PatternPreview from './containers/generator/PatternPreview'

import PatternDetails from './containers/generator/PatternDetails'
import ConfirmEmail from './containers/ConfirmEmail'
import Drive from './containers/Drive'
import Image from "./components/Image"
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom';
import useToken from './hooks/useToken';
import useUser from './hooks/useUser';
import { useWindowSize } from './hooks/useWindowSize'
import strings from "./config/strings";
import Utils from './utils/utils'
import { SECONDARY_COLOR } from "./config/styles";
import { confirmAlert } from 'react-confirm-alert';

import Test from './containers/Test';

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

var __DEV__ = true;

function App() {
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const { size } = useWindowSize();

  if (user)
    var { badgeImg, badgeText } = Utils.getLevelInfo(user.level)


  const renderInner = () => {
    if (!token) {
      return (
        <Switch>

          <Route path="/ConfirmEmail">
            <ConfirmEmail setToken={setToken} setUser={setUser} />
          </Route>

          <Route path="/drive">
            <Drive />
          </Route>

          <Route path="/TestResult/:type">
          <TestResult />
        </Route>


          <Route path="/knitting-crocheting-tools">
            <Tools />
          </Route>
          <Route path="/knitting-swatch-adapter">
            <ComputeExample />
          </Route>
          <Route path="/yarn-skein-converter">
            <ComputeWool />
          </Route>
          <Route path="/knitting-unit-converter">
            <ConvertUnits />
          </Route>
          <Route path="/knitting-needles-yarn-converter">
            <ConvertSizes />
          </Route>
          <Route path="/knitting-stitches-inc-dec-distribution">
            <DistribStitches />
          </Route>

          <Route path="/rc-knitting-crochet-patterns">
            <RcPatterns />
          </Route>

          {(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") &&
            <Route path="/forum">
              <Forum />
            </Route>
          }
          {(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") &&
            <Route path="/chat">
              <Chat />
            </Route>
          }

          <Route path="/glossary">
            <Glossary />
          </Route>


          <Route >
            <Login setToken={setToken} setUser={setUser} />
          </Route>

        </Switch>
      )
    }
    return (
      <Switch>

        <Route path="/Profile">
          <Profile />
        </Route>
        <Route exact path="/">
          <Profile />
        </Route>
        <Route path="/ViewProject/:projectId" component={ViewProject}>
        </Route>

        <Route path="/drive">
          <Drive />
        </Route>

        <Route path="/TestResult/:type">
          <TestResult />
        </Route>

        <Route path="/UploadLocalFile/:projectId">
          <UploadLocalFile />
        </Route>

        <Route path="/NewProject">
          <NewProject />
        </Route>

        <Route path="/ravelry_auth">
          <RavelryAuth />
        </Route>

        <Route path="/ConfirmEmail">
          <ConfirmEmail setToken={setToken} setUser={setUser} />
        </Route>

        <Route path="/knitting-crocheting-tools">
          <Tools />
        </Route>
        <Route path="/knitting-swatch-adapter">
          <ComputeExample />
        </Route>
        <Route path="/yarn-skein-converter">
          <ComputeWool />
        </Route>
        <Route path="/knitting-unit-converter">
          <ConvertUnits />
        </Route>
        <Route path="/knitting-needles-yarn-converter">
          <ConvertSizes />
        </Route>
        <Route path="/knitting-stitches-inc-dec-distribution">
          <DistribStitches />
        </Route>

        <Route path="/rc-knitting-crochet-patterns">
          <RcPatterns />
        </Route>
        <Route path="/knitting-crochet-pattern-builder">
          <PatternList />
        </Route>
        <Route path="/new-row-counter-pattern">
          <InitPattern />
        </Route>
        <Route path="/created-pattern-details/:patternId" component={PatternDetails}>
        </Route>
        <Route path="/edit-pattern/">
          <EditPattern />
        </Route>
        <Route path="/create-section/">
          <CreateSection />
        </Route>
        <Route path="/create-image-section/">
          <CreateImageSection />
        </Route>
        <Route path="/edit-chart/">
          <SchemaGeneratorWithRouter />
        </Route>

        <Route path="/chart-list/">
          <ChartListWithRouter />
        </Route>

        <Route path="/edit-chart-symbol/">
          <EditChartSymbolWithRouter />
        </Route>

        <Route path="/chart-preview/">
          <ShowChartPreviewWithRouter />
        </Route>





        {/* <Route path="/pattern-preview/">
            <PatternPreview/>
          </Route> */}


        <Route path="/glossary">
          <Glossary />
        </Route>


        {(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") &&
          <Route path="/forum">
            <Forum />
          </Route>
        }

        {(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") &&
          <Route path="/chat">
            <Chat />
          </Route>
        }

      </Switch>

    )
  }
  const excludedRoutes = ['/TestResult/maker', '/TestResult/social', '/TestResult/magician','/TestResult/beginner','/TestResult/cozy','/TestResult/creative'];
  const location = window.location;

  return (
    <div className="wrapper" style={{ zIndex: 30 }}>
      <BrowserRouter>
      {!excludedRoutes.includes(location.pathname) &&
         <View style={{ flexDirection: 'row', alignItems: 'center', height: HEADER_HEIGHT, width: '100%', paddingHorizontal: 20, zIndex: 3000 }}>


          <Image urlOnServer={'/imgs/icon_android.png'} style={{ width: 50, height: 50, marginRight: 50 }} />

          <Link style={{ color: '#555', fontWeight: 600 }} to={`/NewProject`}>{strings.rc_web.create_new_proj}</Link>
          <View style={{ backgroundColor: '#555', width: 2, height: 20, marginHorizontal: 10 }} />
          <Link style={{ color: '#555', fontWeight: 600 }} to={`/Profile`}>{strings.rc_web.profile}</Link>
          <View style={{ backgroundColor: '#555', width: 2, height: 20, marginHorizontal: 10 }} />
          <Link style={{ color: '#555', fontWeight: 600 }} to={`/knitting-crocheting-tools`}>{strings.tools}</Link>
          {strings.lang == "en" && <View style={{ backgroundColor: '#555', width: 2, height: 20, marginHorizontal: 10 }} />}
          {strings.lang == "en" && <Link style={{ color: '#555', fontWeight: 600 }} to={`/rc-knitting-crochet-patterns`}>{strings.allrcpatterns.title}</Link>}
          {user && <View style={{ backgroundColor: '#555', width: 2, height: 20, marginHorizontal: 10 }} />}
          {user && <Link style={{ color: '#555', fontWeight: 600 }} to={`/knitting-crochet-pattern-builder`}>{strings.patternCreator.patCreator}</Link>}
          {(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") && <View style={{ backgroundColor: '#555', width: 2, height: 20, marginHorizontal: 10 }} />}
          {(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") && <Link style={{ color: '#555', fontWeight: 600 }} to={`/forum`}>Forum</Link>}
          {(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") && <View style={{ backgroundColor: '#555', width: 2, height: 20, marginHorizontal: 10 }} />}
          {/*(strings.lang == "en" || strings.lang == "fr" || strings.lang == "de") && <Link style={{color:'#555', fontWeight:600}} to={`/chat`}>Chat</Link>*/}
          {/*<View style={{backgroundColor:'#555', width:2, height:20, marginHorizontal:10}}/>*/}
          <Link style={{ color: '#555', fontWeight: 600 }} to={`/glossary`}>{strings.glossaryP.glossary}</Link>
          <View style={{ flex: 1 }} />
          {user &&
            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 0 }}>
              <Text style={{ color: SECONDARY_COLOR, fontWeight: '700' }}>{badgeText}</Text>
              <a href={`/Profile`}>
                <Image urlOnServer={badgeImg} style={{ width: 50, height: 50, marginLeft: 10 }} />
              </a>
              <TouchableOpacity
                style={{ marginLeft: 20 }}
                onPress={() => {


                  confirmAlert({
                    title: '',
                    message: strings.log_out,
                    buttons: [
                      {
                        label: strings.cancel,
                        onClick: () => { }
                      },
                      {
                        label: 'OK',
                        onClick: () => {
                          localStorage.clear();
                          window.location.reload();
                        }
                      }
                    ]
                  });


                }}>
                <Image urlOnServer={'/imgs/logout.png'} style={{ width: 20, height: 20, marginRight: 20 }} />
              </TouchableOpacity>
            </View>
          }
        </View>
      }


        {renderInner()}

      </BrowserRouter>

    </div>
  );
}

export default App;
