import React from 'react';
import {Document, Page, pdfjs} from 'react-pdf/dist/umd/entry.webpack'
import "../config/styles.css"
import Draggable from 'react-draggable';

export default class Highlighter extends React.Component {

  constructor(props) {
    super(props)

   //localStorage.setItem('highlighterHorizontal', 0)
    //var highlighterHorizontalY = parseInt(localStorage.getItem('highlighterHorizontal', 0)) || -(this.props.pdfHeight / 2)

    var storedPosY = parseInt(localStorage.getItem('highlighterHorizontal'))

    var highlighterHorizontalY = storedPosY > -60 ? -(this.props.pdfHeight - 100) : storedPosY

    var highlighterVerticalX = parseInt(localStorage.getItem('highlighterVertical', 0)) || 0

    this.state = {
      defaultPosition: { x: 0, y: highlighterHorizontalY },
      defaultPositionVertical: { x: 0, y: highlighterVerticalX },
      containerHeight: 0,
      highlighterHorizontalHeight : parseInt(localStorage.getItem('highlighterHorizontalHeight', 30)) || 30,
      highlighterVerticalWidth : parseInt(localStorage.getItem('highlighterVerticalWidth', 30)) || 30
    }


  }

  componentDidMount() {
    var containerHeight = this.props.pdfHeight;
    var containerWidth = this.props.pdfWidth;
    console.log("pos storage", parseInt(localStorage.getItem('highlighterHorizontal')), "containerHeight", containerHeight)
    var storedPosY = parseInt(localStorage.getItem('highlighterHorizontal'))

    var highlighterHorizontalY = storedPosY > -60 ? -(this.props.pdfHeight - 100) : storedPosY
    //parseInt(localStorage.getItem('highlighterHorizontal')) || -(this.props.pdfHeight - 100)
   // alert(highlighterHorizontalY)
    if ((Math.abs(highlighterHorizontalY) > containerHeight - 60) && containerHeight > 100)
    {
      
      highlighterHorizontalY = -100
      
      console.log("change")
    }

    var highlighterVerticalX = parseInt(localStorage.getItem('highlighterVertical')) || 100

    if (highlighterVerticalX > containerWidth - 10 || highlighterVerticalX < 0)
    {
      console.log("setting vertical highlighter to 0", containerHeight, highlighterVerticalX)
      highlighterVerticalX = 0

    }
    if (highlighterHorizontalY > 0 || !highlighterHorizontalY)
      highlighterHorizontalY = -100

    console.log("hl", highlighterHorizontalY, "containerHeight", containerHeight)
    //alert(highlighterHorizontalY)
    //highlighterHorizontalY = 0
    //alert(containerHeight + ":" + highlighterHorizontalY + ":" + parseInt(localStorage.getItem('highlighterHorizontal')))
    this.setState({defaultPosition: { x: 0, y: highlighterHorizontalY }
      , defaultPositionVertical: { x: highlighterVerticalX, y: 0 }, containerHeight: containerHeight})
  }

  changeHighlightHorizontalSize(size) {
    if (size < 0) {
      if (this.state.highlighterHorizontalHeight > 12) {
        this.setState({highlighterHorizontalHeight: this.state.highlighterHorizontalHeight + size})
        localStorage.setItem('highlighterHorizontalHeight', this.state.highlighterHorizontalHeight + size)
      }
    }
    else {
      if (this.state.highlighterHorizontalHeight < 100) {
        this.setState({highlighterHorizontalHeight: this.state.highlighterHorizontalHeight + size})
        localStorage.setItem('highlighterHorizontalHeight', this.state.highlighterHorizontalHeight + size)
      }
    }
  }

  changeHighlightVerticalSize(size) {
    if (size < 0) {
      if (this.state.highlighterVerticalWidth > 12) {
        this.setState({highlighterVerticalWidth: this.state.highlighterVerticalWidth + size})
        localStorage.setItem('highlighterVerticalWidth', this.state.highlighterHorizontalHeight + size)
      }
    }
    else {
      if (this.state.highlighterVerticalWidth < 100) {
        this.setState({highlighterVerticalWidth: this.state.highlighterVerticalWidth + size})
        localStorage.setItem('highlighterVerticalWidth', this.state.highlighterVerticalWidth + size)
      }
    }
  }

  renderHighlighterHorizontal() {
    return (


      <Draggable axis="y"
      key={'draggable_' + this.state.containerHeight}
      defaultPosition={this.state.defaultPosition}
      onStop={(e, data) => {


        localStorage.setItem('highlighterHorizontal', data.y);

              }}
        >

    <div style={{width:'100%', height:'50px', position: "absolute", zIndex:220}}>
    <div style={{height: "25px", zIndex:20, zIndex:20}}>
    <button style={{
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        padding: "0",
        textAlign: "center",
        fontSize: "16px",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'rgba(128, 128, 128, 0.5)',
        }}
        onClick={() => this.changeHighlightHorizontalSize(6)}
        >+</button>
  </div>


                <div style={{height: this.state.highlighterHorizontalHeight + "px", width: "100%", backgroundColor: "rgba(255, 255, 0, 0.35)", cursor: "row-resize", zIndex:20}} />


                <div style={{height: "25px"}}>
    <button style={{
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        padding: "0",
        textAlign: "center",
        fontSize: "16px",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'rgba(128, 128, 128, 0.5)',
        }}
        onClick={() => this.changeHighlightHorizontalSize(-6)}
        >-</button>
  </div>
        </div>

            </Draggable>


      );
  }

  renderVerticalHighlighter() {
    return (


      <Draggable axis="x"
      key={'vertical_draggable_' + this.state.containerHeight}
      defaultPosition={this.state.defaultPositionVertical}
      onStop={(e, data) => {
        localStorage.setItem('highlighterVertical', data.x);
        console.log("new highlighter vertical position: " + data.x)
              }}
        >

    <div style={{width:'50px', height:'100%', position: "absolute", top: -25, zIndex:20}}>

    <div style={{display: "flex",
            flexDirection: "row", // Change this
            alignItems: "center",
            justifyContent: "space-between",
            width: this.state.highlighterVerticalWidth + "px"}}>
    <div style={{height: "25px"}}>
    <button style={{
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        padding: "0",
        textAlign: "center",
        fontSize: "16px",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'rgba(128, 128, 128, 0.5)',
        }}
        onClick={() => this.changeHighlightVerticalSize(6)}
        >+</button>
  </div>

  <div style={{height: "25px"}}>
    <button style={{
        width: "25px",
        height: "25px",
        borderRadius: "50%",
        padding: "0",
        textAlign: "center",
        fontSize: "16px",
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'rgba(128, 128, 128, 0.5)',
        }}
        onClick={() => this.changeHighlightVerticalSize(-6)}
        >-</button>
  </div>

  </div>

                <div style={{width: this.state.highlighterVerticalWidth + "px", height: "100%", backgroundColor: "rgba(255, 255, 0, 0.35)", cursor: "row-resize"}} />



        </div>

            </Draggable>


      );
  }

  render() {
    //alert(JSON.stringify(this.props))
    return (<div style={{backgroundColor:'red'}}>
        {this.props.highlighterVerticalEnabled && this.renderVerticalHighlighter()}
        {this.props.highlighterHorizontalEnabled && this.renderHighlighterHorizontal()}

    </div>)
  }
}
